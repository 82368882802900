.Header {
    position: fixed;
    top: 0px;
    margin-top: 0px;
    width: 100%;
    height: 65px;
    background-color: #696969;
    border-bottom: 1px solid #494949;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
}

.HambugerMenuList {
    margin: 0px;
    padding: 0px;
    text-size-adjust: auto;
    list-style: none;
}

.HeaderTitle {
  margin: 0px;
  padding-top: 2px;
  padding-left: 25px;
  transition: color 1s;
  transition: right 1s;
}

.HeaderTitleLink {
  color: black;
}

.HeaderTitleLink:hover {
  color: #b30000;
  text-decoration: none;
}

.Screen {
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  transition: background-color 0.15s;
}

.HamburgerMenu {
   font-family:'Fjalla One', 'Helvetica', 'Arial', 'Sans-Serif';
   position: fixed;
   height: 100%;
   width: 16%;
   font-size: 5vw;
   padding-top: 75px;
   padding-left: 15px;
   padding-right: 15px;
   /*visibility: hidden;*/
   background-color: #696969;
   border-right: 1px solid #494949;
   z-index: 1;
   transition: right 10s;
   visibility: hidden;
}

/*
.HamburgerLink:hover {
  text-decoration: none;
}
*/

.HamburgerButton {
    z-index: 2;
    cursor: pointer;
    margin: 10px;
    margin-left: 15px;
    padding: 5px;
    height: 45px;
    width: 45px;
    border: none;
    background: linear-gradient(
      to bottom, 
      #ffe066, #ffe066 20%, 
      #69696900 20%, #69696900 40%, 
      #ffe066 40%, #ffe066 60%, 
      #69696900 60%, #69696900 80%, 
      #ffe066 80%, #ffe066 100%
    );
}

.HamburgerButton:hover {
    background: linear-gradient(
      to bottom, 
      #b30000, #b30000 20%, 
      #69696900 20%, #69696900 40%, 
      #b30000 40%, #b30000 60%, 
      #69696900 60%, #69696900 80%, 
      #b30000 80%, #b30000 100%
    );
}