@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Libre+Baskerville');

html, body {
  font-family:'Libre Baskerville', 'Georgia', 'Times New Roman', 'Serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  height: 100%;
  position: relative;
  bottom: 0;
}

code {
  font-family:'Fjalla One', 'Helvetica', 'Arial', 'Sans-Serif';
}

header {
  margin: 0;
  padding: 0;
}

div#root {
  position: relative;
  margin: 0 auto;
  padding: 0 auto;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  background-color: #ffe066;
  border-right: 1px solid #494949;
}

a {
  text-decoration: none;
  color: #b30000;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 3em;
  font-family:'Fjalla One', 'Helvetica', 'Arial', 'Sans-Serif';
}

p {
  font-size: 1.5em;
  line-height: 1.4em;
  color: #333;
}
