.LayoutWrapper {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
}


.OutletWrapper {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 80vh;
    padding-top: 75px;
    padding-bottom: 0px;
    width: 80%;
    height: auto !important;
    height: 80vh;
}