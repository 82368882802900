#PostDiv {
    margin-top: 15px;
    margin-bottom: 0px;
}

.BackLink:hover {
    text-decoration: none;

}

.BackButton {
    border: 1px solid;
    border-radius: 10px;
    border-color: #494949;
    margin: 0px;
    padding: 10px;
    background-color: #696969;
    color: black;
    width: fit-content;
}

.BackButton:hover {
    border: 5px ridge;
    border-radius: 10px;
    border-color: #b30000;
    padding: 6px;
}

.PostBlockDate {
    font-size: 1em;
    line-height: 0.9em;
    margin: 0px;
    padding: 0px;
}